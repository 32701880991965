<template>
    <div class="main">
        <div class="wrapper">
            <Header :outletName="outletName" />
            <v-container grid-list-xs>
                <v-row v-if="user.length < 1">
                    <v-col cols="12">
                        <v-alert outlined type="info" text>
                            Please add your detail to continue
                        </v-alert>
                    </v-col>
                </v-row>
                <h2 class="mb-1" style="color: grey; font-weight: 400; font-family: 'Poppins', sans-serif;">User Details</h2>
                <v-divider></v-divider>
                <v-row>
                    <v-col>
                        <input type="radio" name="selectedUser" :id="'user_' + selectedUser.id" v-model="selectedUserId" :value="selectedUser.id">
                                <label :for="'user_' + selectedUser.id">{{ selectedUser.name }}</label>
    
                        <v-window v-model="step">
                            <v-window-item :value="1">
                                <div color="white" class="rounded-t-xl "
                                    v-if="selectedUser">
                                    <v-row class="my-2" v-if="selectedUser">
                                        <v-col cols="12">
                                            <div class="flex flex-column source ml-3" style="line-height: 1.5">
                                                <h3>{{ selectedUser.name }}</h3>
                                                <h3>{{ selectedUser.phone }}</h3>
                                                <div class="flex justify-space-between">
                                                    <h5 class="font-italic py-1">
                                                        {{ selectedUser.email }}
                                                    </h5>
                                                    <v-btn  @click="editUser(selectedUser)" color="#F85757" class="rounded-pill font-weight-bold white--text" small
                                                        elevation="0">Default</v-btn>
                                                </div>
                                                <h5 class="lato">{{ selectedUser.address }}</h5>
                                                <h5 class="lato">{{ selectedUser.address2 }}</h5>
                                                <h5 class="lato">{{ selectedUser.postcode }}</h5>   
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                </div>
                                <div v-if="this.unselectedUser.length > 0">
                                    <div v-for="(v, i) in this.unselectedUser" :key="i" @click="() => editUser(v)">
                                        <div>
                                            <v-row class="">
                                                <v-col cols="23">
                                                    <v-row class="my-2" v-if="v">
                                                        <v-col cols="12">
                                                            <div class="flex flex-column source ml-3"
                                                                style="line-height: 1.5">
                                                                <h3>{{ v.name }}</h3>
                                                                <h3>{{ v.phone }}</h3>
                                                                <div class="flex justify-space-between">
                                                                    <h5 class="font-italic py-1">
                                                                        {{ v.email }}
                                                                    </h5>
                                                                </div>
                                                                <h5 class="lato">{{ v.address }}</h5>
                                                                <h5 class="lato">{{ v.address2 }}</h5>
                                                                <h5 class="lato">{{ v.postcode }}</h5>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <v-divider></v-divider>
                                    <v-btn block large class="my-3" color="#F85757" rounded dark @click="step++">
                                        <div class="flex">
                                            <v-icon>mdi-plus</v-icon>
                                            <h4 class="mt-1 ml-3">Add New User</h4>
                                        </div>
                                    </v-btn>
                                </div>
                            </v-window-item>
                            <v-window-item :value="2">
                                <v-card color="white" class="rounded-t-xl pa-3" min-height="90vh">
                                    <v-row>
                                        <v-col cols="12">
                                            <div class="flex flex-column blue-grey--text ml-3 mt-3" style="line-height: 1">
                                                <v-form ref="form" class="mx-2" lazy-validation>
                                                    <v-text-field name="name" outlined color="black" rounded clearable
                                                        :rules="[(v) => !!v || 'Name is required']" label="Name" dense
                                                        v-model="newUser.name"></v-text-field>

                                                        <v-text-field name="name" outlined color="black" rounded clearable dense
                                                        :rules="[(v) => !!v || 'Phone Number is required']"
                                                        label="Phone Number" v-model="newUser.phone"></v-text-field>
                                                    <v-text-field name="name" outlined color="black" rounded type="email"
                                                        dense clearable :rules="[
                                                            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid', (v) => !!v || 'Email is required'
                                                        ]" label="Email" v-model="newUser.email"></v-text-field>
                                                    <v-text-field v-if="source !== 'shop_link'" name="name" outlined color="black" rounded clearable
                                                        :rules="[(v) => !!v || 'Address is required']" label="Address" dense
                                                        v-model="newUser.address"></v-text-field>
                                                    <v-text-field name="name" outlined color="black" rounded clearable dense
                                                        :rules="[(v) => !!v || 'Address 2 is required']" label="Address 2"
                                                        v-model="newUser.address2"></v-text-field>
                                                    <v-text-field name="name" outlined color="black" rounded clearable dense
                                                        :rules="[
                                                            (v) => !!v || 'Postcode is required',
                                                            (v) =>
                                                                (v && v.length <= 5) ||
                                                                'Postcode must be less than 5 characters',
                                                        ]" label="Postcode" counter="5"
                                                        v-model="newUser.postcode"></v-text-field>
                                                </v-form>
                                            </div>

                                            
                                            <div class="flex flex-column blue-grey--text ml-3 mt-3"
                                                v-if="newUser.address && newUser.address2">
                                                <v-btn elevation="2" @click="getUpdateGeo()" v-if="showMaps == false">Check
                                                    Pin
                                                    Point</v-btn>
                                                <v-card class="justify-center" elevation="2" v-else>
                                                    <div class="text-center" v-if="loading != 1">
                                                        <v-progress-circular indeterminate
                                                            color="green"></v-progress-circular>
                                                    </div>
                                                    <div v-else>
                                                        <gmap-map :zoom="14" :center="center"
                                                            :options='{ disableDefaultUI: true }'
                                                            style="width:100%;  height: 300px;">
                                                            <gmap-marker :position="center" :draggable="true"
                                                                @dragend="updateCoordinates"></gmap-marker>
                                                        </gmap-map>
                                                    </div>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-spacer></v-spacer>
                                    </v-row>

                                    <v-checkbox class="ml-5" label="Set as primary" @click="set(newUser)"> </v-checkbox>
                                    <div>
                                        <v-row>
                                            <v-spacer></v-spacer>
                                            <v-col cols="12" class="ml-3" >
                                                <v-card-actions>
                                                    <v-btn rounded color="white"  class="red--text" width="107px" height="48px" @click="step--">back</v-btn>
                                                    <v-btn rounded color="red" class="white--text" width="107px" height="48px" @click="save('save')">save</v-btn>
                                                    <!-- <v-btn rounded  width="187px" height="48px" @click="set(newUser)">Set as primaryyyyy</v-btn> -->
                                                </v-card-actions>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                            </v-window-item>
                        </v-window>
                    </v-col>
                </v-row>
                <v-col cols="12">
                      <v-card-actions>
                        <v-btn text @click="navigateToProduct" style="background-color: grey; color: white;">back to menu</v-btn>
                        <v-btn  text @click="save('save')" class="ml-4" style="background-color: #F85757; color: white;"
                          >view cart</v-btn
                        >
                        
                      </v-card-actions>
                    </v-col>
            </v-container>

        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from '../common/Header.vue'

export default {
    name: "Users",
    components: {
        Header,
    },

    data() {
        return {
            selectedUserId: null,
            outletName: "Safta",
            step: 1,
            status: null,
            newUser: {
                name: "",
                address: "",
                address2: "",
                postcode: "",
                email: "",
                phone: ""
            },
            rules: [(v) => !!v || "Name is required"],
            showSave: false,
            showMaps: false,
            center: {},
            locationMarkers: null,
            locPlaces: [],
            existingPlace: null,
            loading: 0
        };
    },
    computed: {
        ...mapGetters({
            user: "getUsers",
        }),
        selectedUser() {
            return this.user.find((d) => d.selected == true);
        },
        unselectedUser() {
            return this.user.filter((d) => d.selected == false);
        },
    },

    methods: {
        updateCoordinates(location) {
            this.center = {
                lat: location.latLng.lat(),
                lng: location.latLng.lng(),
            };
        },
        navigateToProduct() {
            this.$router.push({
                    name: "Page",
                    params: { child: this.$route.params.child, page: "products" },
                });
      localStorage.removeItem("selectedProduct");
    },
        toCart() {
            if (this.user.length < 1) {
                this.$router.push({
                    name: "Page",
                    params: { child: this.$route.params.child, page: "products" },
                });
            } else
                this.$router.push({
                    name: "Page",
                    params: { child: this.$route.params.child, page: "cart" },
                });
        },

        select() {
            this.showSave = true;
        },

        set(i) {
            let a = this.user.findIndex((d) => d.name == i.name);
            this.$store.dispatch("setUser", a);
            this.step = 1;
        },

        editUser(value) {
            let a = this.user.findIndex((d) => d.name == value.name);
            this.status = a;
            this.newUser = { ...value };
            this.selectedUserId = value.id;
            this.step++;
        },

        async getUpdateGeo() {
            this.showMaps = true;
            let data = null;
            let a = this.user.findIndex((d) => d.name == this.newUser.name);
            let b = this.user[a];

            data = {
                address: this.newUser.address,
                address2: this.newUser.address2
            }

            if (a >= 0) {
                if (
                    b.address != this.newUser.address ||
                    b.address2 != this.newUser.address2 ||
                    !b.lat
                ) {
                    let fullAddr = await this.$store.dispatch("fetchGeocode", data);
                    this.loading = 1;
                    this.center = {
                        lat: fullAddr.latitude,
                        lng: fullAddr.longitude,
                    };
                } else {
                    this.loading = 1;
                    this.center = {
                        lat: b.lat,
                        lng: b.lng,
                    };
                }
            } else {
                let fullAddr = await this.$store.dispatch("fetchGeocode", data);
                this.loading = 1;
                this.center = {
                    lat: fullAddr.latitude,
                    lng: fullAddr.longitude,
                };
            }
        },

        save() {
            let a = this.$refs.form.validate();
            if (this.center == null) {
                this.getUpdateGeo();
            }
            if (a) {
                this.$store
                    .dispatch("saveUser", {
                        user: { ...this.newUser, ...this.center },
                        status: this.status,
                    })
                    .then(() => {
                        this.status = null;
                        this.newUser = {
                            name: "",
                            address: "",
                            address2: "",
                            postcode: "",
                            email: "",
                            phone: "",
                        };
                        this.$router.push({
                            name: "Page",
                            params: { child: this.$route.params.child, page: "cart" },
                        });
                    });
            }
        },
    },
};
</script>
<style scoped>
.red_tab_text {
  color: #F85757 !important;
}
</style>
  